import { IoIosArrowForward } from "react-icons/io";
import { MdHelp } from "react-icons/md";
import more from "../assets/images/more.png";
import { useDispatch, useSelector } from "react-redux";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { setSideDrawerTab } from "../redux/reducer/side_drawer_tab";
import { toggleSideDrawer } from "../redux/reducer/side_drawer";

const SideDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOpen = useSelector((e: any) => e.sideDrawer.isOpen);
  const tab = useSelector((state: any) => state.sideDrawerTab.tab);

  const handlePress = () => {
    dispatch(toggleSideDrawer());
  };

  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;

  return (
    <>
      {isOpen && (
        <aside
          className={`fixed md:static top-0 left-0 h-screen w-60 bg-red-600 p-4 transition-transform duration-300 z-50 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } md:translate-x-0`}
        >
          <button
            className="text-2xl md:hidden" // `md:hidden` hides the button on medium and larger screens
            aria-label="Toggle menu"
            onClick={() => handlePress()}
          >
            <img src={more} className="w-8 h-8 object-cover" />
          </button>

          {/* <div className="p-2 flex justify-center items-center border-b border-white">
            <img src={whiteLogo} className="w-10 h-10 object-contain " />
          </div> */}
          <nav className="flex-1">
            <div className="px-4 py-2 text-[15px] uppercase text-white mt-4">
              MODULES
            </div>

            <div
              className={`py-2 px-4 side-drader-box flex cursor-pointer justify-between items-center mt-2 ${
                tab === 0 ? "bg-white" : "bg-[#ED2427]"
              }`}
              onClick={() => {
                navigate("/"), dispatch(setSideDrawerTab({ tab: 0 }));
              }}
            >
              <div className="flex items-center">
                <FaRegUser
                  className="mr-2"
                  color={tab === 0 ? "#000" : "#fff"}
                  size={20}
                />
                <span
                  className={`ml-2 ${tab === 0 ? "text-black" : "text-white"}`}
                >
                  {userExist?.status == 1 ? "Dashboard" : "Customers"}
                </span>
              </div>
              <IoIosArrowForward
                className="mr-2 ml-1"
                color={tab === 0 ? "#000" : "#fff"}
                size={16}
              />
            </div>

            {userExist?.status == 1 ? (
              <div
                className={`py-2 px-9 side-drader-box flex cursor-pointer justify-between items-center mt-3 ${
                  tab === 1 ? "bg-white" : "bg-[#ED2427]"
                }`}
                onClick={() => {
                  navigate("/help"), dispatch(setSideDrawerTab({ tab: 1 }));
                }}
              >
                <div className="flex items-center">
                  <MdHelp
                    className="mr-2"
                    color={tab === 1 ? "#000" : "#fff"}
                    size={20}
                  />
                  <span
                    className={`ml-2 ${
                      tab === 1 ? "text-black" : "text-white"
                    }`}
                  >
                    Help
                  </span>
                </div>
                <IoIosArrowForward
                  className="mr-2 ml-1"
                  color={tab === 1 ? "#000" : "#fff"}
                  size={16}
                />
              </div>
            ) : null}

            {/* <div
              className={`py-2 px-6 side-drader-box flex cursor-pointer justify-between items-center mt-3 ${
                tab === 2 ? "bg-white" : "bg-[#ED2427]"
              }`}
              onClick={() => {
                navigate("/setting"), dispatch(setSideDrawerTab({ tab: 2 }));
              }}
            >
              <div className="flex items-center">
                <MdSettings
                  className="mr-2"
                  color={tab === 2 ? "#000" : "#fff"}
                  size={20}
                />
                <span
                  className={`ml-2 ${tab === 2 ? "text-black" : "text-white"}`}
                >
                  Settings
                </span>
              </div>
              <IoIosArrowForward
                className="mr-2"
                color={tab === 2 ? "#000" : "#fff"}
                size={16}
              />
            </div> */}
          </nav>
        </aside>
      )}
    </>
  );
};

export default SideDrawer;
