import { Mail } from "lucide-react";
import logo from "../../assets/images/logo.png";

const ForgetPassword = () => {
  return (
    <div className="bg-hero bg-cover bg-center h-screen w-screen flex flex-col md:flex-row items-center justify-center">
      {/* Left Side */}
      <div className="hidden md:flex md:w-1/2 flex-col items-center">
        <div className="text-center mb-8 mt-20">
          {/* Optional title for larger screens */}
        </div>
      </div>

      {/* Right Side (Forget Password Form) */}
      <div className="w-full md:w-1/2 flex items-center justify-center px-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 sm:p-8">
          <div className="mb-6 flex justify-center">
            <img src={logo} className="w-12 h-16 sm:w-16 sm:h-20" />
          </div>
          <h3 className="text-lg sm:text-xl font-semibold text-center mb-4">
            Forgot Password
          </h3>
          <form>
            <div className="mb-4">
              <h2 className="mb-1">Email</h2>

              <div className="w-full px-3 py-2 flex items-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                <Mail color="#7F7F7F" />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full pl-3 focus:outline-none"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Send link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
