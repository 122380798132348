import { LogOut } from "lucide-react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

interface props {
  openModal: boolean;
  onClose: Function;
}

const profileModal = ({ openModal, onClose }: props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;

  const handleSignOut = () => {
    localStorage.clear();
    onClose(!openModal);
    navigate("/");
    window.location.reload();
  };

  if (!openModal) return null;
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => onClose(!openModal)}
        />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={modalRef}
          className="inline-block transform overflow-hidden rounded-lg bg-white transition-all absolute p-4 right-5 top-12"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
        >
          <div className="flex flex-col items-center">
            <div className="bg-red-400 w-10 h-10 text-xl font-semibold items-center content-evenly rounded-3xl mb-3 pb-2">
              {userExist?.status == 1 ? userExist?.name.charAt(0) : "A"}
            </div>
            <label className="text-black font-semibold">
              {userExist?.status == 1 ? userExist?.name : "Admin"}
            </label>
            <label className="text-gray-600 text-sm mb-3">
              {userExist?.email}
            </label>
            <div
              onClick={() => handleSignOut()}
              className="flex flex-row items-center hover:bg-gray-200 border p-1 px-3 gap-1 rounded-2xl"
            >
              <LogOut className="text-black w-5 h-5" />
              <label className="text-black font-semibold">Sign Out</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default profileModal;
