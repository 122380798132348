import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";

const BackButtonComp = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={handleBack} className="flex items-center gap-1">
      <ChevronLeft size={18} />
      <p>Back</p>
    </button>
  );
};

export default BackButtonComp;
