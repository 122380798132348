import React from "react";
import { Send } from "lucide-react";
import SideDrawer from "../../components/SideDrawer";
import CustomHeader from "../../components/CustomHeader";
import BackButtonComp from "../../components/BackButtonComp";
import ApiManager from "../../api/ApiManager";
import { toast, ToastContainer } from "react-toastify";

const Help = () => {
  const [message, setMessage] = React.useState("");

  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;

  const HelpAPI = async (event: React.FormEvent) => {
    event.preventDefault();
    const params = {
      name: userExist?.name,
      message: message,
    };

    await ApiManager.helpApi(params)
      .then((res) => {
        toast(res?.data?.message);
        setMessage("");
      })
      .catch((err) => {
        toast(err);
      });
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <CustomHeader />
      <div className="flex flex-col md:flex-row h-full bg-gray-100">
        <SideDrawer />

        <div className="flex flex-col w-full p-4 sm:p-6 md:p-8">
          <BackButtonComp />
          <h1 className="text-2xl font-semibold mb-4 text-center md:text-left pl-3">
            Help
          </h1>
          <div className="w-full bg-white rounded-lg shadow-md overflow-hidden p-6 sm:p-8 md:p-12">
            <main>
              <form className="space-y-4" onSubmit={HelpAPI}>
                <div>
                  <label
                    htmlFor="query"
                    className="block text-sm font-medium text-black mb-3"
                  >
                    Ask your question
                  </label>
                  <div className="border-t-2 border-gray-300 mb-3" />

                  <label
                    htmlFor="query"
                    className="block text-sm text-black mb-3"
                  >
                    Write your query here
                  </label>
                  <textarea
                    id="query"
                    name="query"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-red-500"
                    placeholder="Write here ..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center justify-center"
                >
                  <span>Submit</span>
                  <Send className="ml-2 h-4 w-4" />
                </button>
              </form>
            </main>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Help;
