// src/components/NotificationProvider.tsx
import React, { ReactNode } from "react";
import useFcmToken from "../hooks/useFcmToken";
import useNotification from "../hooks/useNotification";

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  useFcmToken(); // Initialize and store the FCM token
  useNotification(); // Listen for incoming notifications

  return <>{children}</>;
};

export default NotificationProvider;
