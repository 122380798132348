import { useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import ApiManager from "../../api/ApiManager";
import { toast, ToastContainer } from "react-toastify";
import OtpInput from "react-otp-input";
import { Mail } from "lucide-react";

const Login = () => {
  const navigate = useNavigate();

  const [inputValue, setinputValue] = useState("");
  const [inputType, setinputType] = useState("");
  const [iserrorMsg, setIserrorMsg] = useState(true);
  const [otp, setOtp] = useState("");
  const [api_resp, setapi_resp] = useState({
    data: {},
    otp: "",
  });

  const login = () => {
    const body = {
      input: inputValue,
      type: inputType,
    };

    ApiManager.customerlogin(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          localStorage.setItem("userData", JSON.stringify(resp.data.data));
          toast(resp.data.message);
          setapi_resp({
            data: resp.data.data,
            otp: resp.data.otp,
          });
          addFCMTokenAPI();
        } else {
          // console.log("wrong credential");
          toast(resp.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const addFCMTokenAPI = () => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userId = userData?.id;
    const FCMToken = localStorage.getItem("fcmToken");
    const params = {
      userId: userId,
      fcmToken: FCMToken,
    };

    ApiManager.addFcmToken(params).then(
      (resp: { data: { status: boolean; data: any } }) => {
        if (resp?.data?.status == true) {
          console.log("data222", resp.data);
        }
      }
    );
  };

  const validateInput = (input: string) => {
    setinputValue(input);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const mobileRegex = /^(\+?\d{1,4}[-.\s]?)?(\d{10})$/;

    if (emailRegex.test(input)) {
      setIserrorMsg(false);
      setinputType("email");
    } else if (mobileRegex.test(input)) {
      setIserrorMsg(false);
      setinputType("mobile");
    } else {
      setIserrorMsg(true);
    }
  };

  const handleVerify = () => {
    if (api_resp.otp == otp) {
      localStorage.setItem("userData", JSON.stringify(api_resp.data));
      navigate("/");
      window.location.reload();
    } else if (otp !== api_resp?.otp) {
      toast("please enter valid OTP");
    }
  };

  return (
    <div className="bg-hero bg-cover bg-center h-screen w-screen flex flex-col md:flex-row items-center justify-center">
      {/* Left Side */}
      <div className="hidden md:flex md:w-1/2 flex-col items-center">
        <div className="text-center mb-8 mt-20">
          {/* Optional title for larger screens */}
        </div>
      </div>

      {/* Right Side (Login Form) */}
      <div className="w-full md:w-1/2 flex items-center justify-center">
        <div className="w-11/12 sm:w-4/5 md:w-full max-w-md bg-white rounded-lg shadow-md p-6 sm:p-8">
          <div className="mb-6 flex justify-center">
            <img src={logo} className="w-12 h-16 sm:w-16 sm:h-20" alt="Logo" />
          </div>
          <h3 className="text-lg sm:text-xl font-semibold text-center mb-4">
            Customer Login
          </h3>

          {api_resp.otp.length > 1 ? (
            <div className="mb-4 flex flex-col items-center">
              <OtpInput
                value={otp}
                onChange={setOtp}
                renderInput={(props) => <input {...props} />}
                numInputs={5}
                inputType="tel"
                shouldAutoFocus
                inputStyle={{
                  width: "2.5rem",
                  height: "2.5rem",
                  margin: "0 0.3rem",
                  fontSize: "1.25rem",
                  borderRadius: "4px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  textAlign: "center",
                }}
              />
            </div>
          ) : (
            <div className="mb-4">
              <h2 className="mb-1">Email / Mobile No</h2>

              <div className="w-full px-3 py-2 flex items-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500">
                <Mail color="#7F7F7F" />
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full pl-3 focus:outline-none"
                  value={inputValue}
                  onChange={(e) => validateInput(e.target.value)}
                />
              </div>

              {inputValue.length > 1 && iserrorMsg && (
                <h5 className="text-red-500 text-sm mt-1 ml-2">
                  Please enter a valid Mobile No / Email
                </h5>
              )}
            </div>
          )}

          <button
            disabled={iserrorMsg}
            className={`w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              iserrorMsg ? "bg-gray-500" : "bg-red-600 hover:bg-red-700"
            }`}
            onClick={() => {
              api_resp.otp.length > 1 ? handleVerify() : login();
            }}
          >
            {api_resp.otp.length > 1 ? "Verify OTP" : "Login"}
          </button>
          <div
            onClick={() => navigate("/")}
            className="mt-3 cursor-pointer flex justify-center"
          >
            <a className="text-center hover:text-red-600">Login as Admin</a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
