import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import BackButtonComp from "../components/BackButtonComp";

const Setting = () => {
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        {/* ---------------- */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <BackButtonComp />
          Settings
        </main>
      </div>
    </div>
  );
};

export default Setting;
