import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const SideDrawerTab = createSlice({
  name: "SideDrawerTab",
  initialState,
  reducers: {
    setSideDrawerTab: (_, action) => {
      return {
        tab: action.payload.tab,
      };
    },
  },
});

export default SideDrawerTab.reducer;
export const { setSideDrawerTab } = SideDrawerTab.actions;
