import { X } from "lucide-react";
import { useState } from "react";

interface props {
  isOpen: boolean;
  data: string;
  onClose: Function;
  onSave: Function;
}

const FeedBackModal = ({ isOpen, data, onClose, onSave }: props) => {
  const [text, settext] = useState(data);

  const onBtnClick = () => {
    onSave(text);
    settext("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full sm:w-1/2 max-w-xs sm:max-w-md p-4">
        <div className="flex justify-between items-center">
          <label className="font-medium text-lg sm:text-xl">Feedback</label>
          <X
            className="w-5 h-5 cursor-pointer"
            onClick={() => onClose(false)}
          />
        </div>
        <div className="mt-2">
          <label className="text-gray-600 text-sm sm:text-base">
            Write Feedback
          </label>
          <textarea
            value={text === "" ? data : text}
            placeholder="Write comment"
            onChange={(e) => settext(e.target.value)}
            className="w-full p-2 mt-2 border rounded-md text-sm sm:text-base"
            rows={4}
            required
          />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => onBtnClick()}
            className="bg-blue-500 text-white py-2 px-6 sm:px-4 rounded-md hover:bg-blue-600 text-sm sm:text-base"
          >
            Save feedback
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedBackModal;
