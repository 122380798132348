import { CSSProperties } from "react";
import FadeLoader from "react-spinners/FadeLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

interface props {
  loading: boolean;
}

const Spinner = ({ loading }: props) => {
  return loading ? (
    <div
      className="flex justify-center items-center absolute top-0 left-0 w-full h-full"
      style={{ backgroundColor: "rgba(211, 211, 211, 0.2)", zIndex: 10 }}
    >
      <FadeLoader
        color={"red"}
        loading={loading}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : null;
};

export default Spinner;
