export const phasesList = [
  {
    id: 1,
    name: "WO Acceptance",
  },
  {
    id: 2,
    name: "POA & BG Wetting",
  },
  {
    id: 3,
    name: "Mfg. Clearance",
  },
  {
    id: 4,
    name: "Production Schedule",
  },
  {
    id: 5,
    name: "WDG",
  },
  {
    id: 6,
    name: "Dry Out",
  },
  {
    id: 7,
    name: "Internal Testing",
  },
  {
    id: 8,
    name: "RITES/Third Party Inspection",
  },
  {
    id: 9,
    name: "On Road Updates",
  },
  {
    id: 10,
    name: "Unloading",
  },
  {
    id: 11,
    name: "Checking of Transformer",
  },
  {
    id: 12,
    name: "Acceptance of Trs. at site ",
  },
  {
    id: 13,
    name: "LC/VFS Acceptance/Payment",
  },
  {
    id: 14,
    name: "Service Call for Supervision Service",
  },
  {
    id: 15,
    name: "SOP + Undertaking",
  },
  {
    id: 16,
    name: "Deputation Details",
  },
  {
    id: 17,
    name: "ETC Work at Site",
  },
  {
    id: 18,
    name: "Certification",
  },
  {
    id: 19,
    name: "Service Call 2nd for Commissioning",
  },
  {
    id: 20,
    name: "Pre-Commissioning Checks",
  },
  {
    id: 21,
    name: "Commissioning",
  },
];
