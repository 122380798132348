import { X } from "lucide-react";
import { useState } from "react";

interface props {
  isOpen: boolean;
  data: string;
  onClose: Function;
  onSave: Function;
}

const FeedBackSeenModal = ({ isOpen, data, onClose, onSave }: props) => {
  const [text, settext] = useState("");

  const onBtnClick = () => {
    onSave(text);
    settext("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-1/2 p-4">
        <div className="flex justify-between">
          <label className="font-medium text-xl">Feedback</label>
          <X className="w-5 h-5" onClick={() => onClose(false)} />
        </div>
        <div className="border p-2 my-3 rounded-lg">
          <label>{data}</label>
        </div>

        <div className="mt-4">
          <button
            onClick={() => onBtnClick()}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedBackSeenModal;
