import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
};

const SideDrawer = createSlice({
  name: "SideDrawer",
  initialState,
  reducers: {
    toggleSideDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeSideDrawer: (state) => {
      state.isOpen = false;
    },
  },
});

export default SideDrawer.reducer;
export const { toggleSideDrawer, closeSideDrawer } = SideDrawer.actions;
