// src/hooks/useFcmToken.ts
import { useState, useEffect } from "react";
import { messaging, getToken } from "../../firebaseConfig";

const useFcmToken = () => {
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Notification permission not granted.");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchToken = async () => {
      const hasPermission = await requestNotificationPermission();
      if (!hasPermission) return;

      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BAtMXxCGmmzPCO3TQxoGdUkifLZKgzaSyKpPCRrfufWvftKeG2nAcLtvTmgMAz-ZE1Os0eOPFaAJ4sn3MTg-QEQ",
          serviceWorkerRegistration: await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js"
          ),
        });

        if (currentToken) {
          setFcmToken(currentToken);
          localStorage.setItem("fcmToken", currentToken);
          console.log("FCM Token:", currentToken);
        } else {
          console.warn("No registration token available.");
        }
      } catch (error) {
        console.error("Error retrieving token: ", error);
      }
    };

    fetchToken();
  }, []);

  return fcmToken;
};

export default useFcmToken;
