import { Upload, X } from "lucide-react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
interface props {
  openModal: boolean;
  setOpenModal: Function;
  getInputData: Function;
  data: any;
}

interface Image {
  name: string;
}

const AddPhaseModal = ({
  openModal,
  setOpenModal,
  getInputData,
  data,
}: props) => {
  const [phaseData, setphaseData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    description: "",
    image: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const [nameError, setnameError] = useState(false);
  const [phaseStartDate, setphaseStartDate] = useState(false);
  const [phaseEndDate, setphaseEndDate] = useState(false);
  const [phaseDescError, setphaseDescError] = useState(false);

  const ValidatePhaseInput = () => {
    if (phaseData.name == "") {
      setnameError(true);
    } else if (phaseData.start_date == "") {
      setphaseStartDate(true);
    } else if (phaseData.end_date == "") {
      setphaseEndDate(true);
    } else if (phaseData.description == "") {
      setphaseDescError(true);
    } else {
      getInputData(phaseData);
      setOpenModal(!openModal);
      setphaseData({
        name: "",
        start_date: "",
        end_date: "",
        description: "",
        image: "",
      });
    }
  };

  const handlePhaseInput = (field: any, e: string) => {
    setphaseData((prev) => ({
      ...prev,
      [field]: e,
    }));
  };

  const handleUpload = () => {
    if (selectedImage) {
      setphaseData((prev) => ({
        ...prev,
        ["image"]: selectedImage,
      }));
    } else {
      console.log("No image selected");
    }
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  if (!openModal) return null;

  interface DataItem {
    name: string;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-sm w-full p-6">
        <div className="flex flex-row justify-between mb-4">
          <h2 className="text-xl font-semibold">Create Phase</h2>
          <button
            onClick={() => {
              setOpenModal(!openModal);
            }}
            className="text-gray-600"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2">
          <div className="items-center space-y-2">
            <label
              htmlFor="phaseName"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Select Phase<label className="text-red-600">*</label>
            </label>
            <select
              id="phaseName"
              onChange={(e) => {
                handlePhaseInput("name", e.target.value);
                setnameError(false);
              }}
              className="bg-white px-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option selected>Select...</option>
              {data.map((val: DataItem, i: number) => (
                <option value={val.name} key={i}>
                  {val.name}
                </option>
              ))}
            </select>
            {nameError && <Validation name={"please select type of phase"} />}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                Start date<label className="text-red-600">*</label>
              </label>
              <div className="mt-1 relative">
                <input
                  type="date"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-1 px-2"
                  value={phaseData.start_date}
                  // min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    handlePhaseInput("start_date", e.target.value);
                    setphaseStartDate(false);
                  }}
                />
                {phaseStartDate && (
                  <Validation name={"please enter start date"} />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700"
              >
                End date<label className="text-red-600">*</label>
              </label>
              <div className="mt-1 relative">
                <input
                  type="date"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-1 px-2"
                  value={phaseData.end_date}
                  min={phaseData.start_date}
                  onChange={(e) =>
                    phaseData.start_date
                      ? (handlePhaseInput("end_date", e.target.value),
                        setphaseEndDate(false))
                      : toast("Please select start date!")
                  }
                />
                {phaseEndDate && <Validation name={"please enter end date"} />}
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description<label className="text-red-600">*</label>
            </label>
            <textarea
              rows={2}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              placeholder="Write here..."
              value={phaseData.description}
              onChange={(e) => {
                handlePhaseInput("description", e.target.value);
                setphaseDescError(false);
              }}
            />
            {phaseDescError && <Validation name={"please enter description"} />}
          </div>

          <div>
            <label
              htmlFor="uploadImage"
              className="block text-sm font-medium text-gray-700"
            >
              Upload Image
            </label>
            <div className="mt-1 flex items-center space-x-4">
              <label
                htmlFor="uploadImage"
                className="cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm px-4 py-1 inline-flex items-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <Upload
                  className="mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {(phaseData.image as unknown as Image)?.name
                  ? (phaseData.image as unknown as Image)?.name
                  : "Choose Image"}
              </label>
              <button
                type="button"
                className="px-4 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
                onClick={handleUpload}
              >
                Upload Image
              </button>
            </div>
            <input
              id="uploadImage"
              name="uploadImage"
              type="file"
              className="sr-only"
              onChange={handleImageChange}
            />
          </div>

          <div className="flex gap-4">
            <button
              type="submit"
              onClick={() => ValidatePhaseInput()}
              className="w-full md:w-auto mt-2 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
            >
              Next
            </button>
            <button
              type="button"
              onClick={() => setOpenModal(!openModal)}
              className="w-full md:w-auto mt-2 px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddPhaseModal;

interface props1 {
  name: string;
}

const Validation = ({ name }: props1) => {
  return <label className="text-[11px] text-red-500">{name}</label>;
};
