// src/hooks/useNotification.ts
import { useEffect } from "react";
import { onMessage, messaging } from "../../firebaseConfig";

const useNotification = () => {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);

      // Display an alert or handle the notification as needed
      alert(
        `New notification: ${payload.notification?.title} - ${payload.notification?.body}`
      );
    });

    return () => {
      // Cleanup the listener on component unmount
      unsubscribe();
    };
  }, []);
};

export default useNotification;
