// firebase-config.ts
// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4pTWTPZAb5wbwxZqyzEuIaLoJAx3x4e4",
  authDomain: "vishwas-44c3b.firebaseapp.com",
  projectId: "vishwas-44c3b",
  storageBucket: "vishwas-44c3b.firebasestorage.app",
  messagingSenderId: "970451870588",
  appId: "1:970451870588:web:26676ef7b9543833f3baf9",
  measurementId: "G-8K3ZDGWRRQ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { firebaseApp, messaging, getToken, onMessage };
