import { useRef, useState } from "react";
import { X } from "lucide-react";

interface customerModal {
  isOpen: boolean;
  onClose: Function;
  onSave: Function;
}

export default function CustomerModal({
  isOpen,
  onClose,
  onSave,
}: customerModal) {
  const modalRef = useRef<HTMLDivElement>(null);

  const [customerData, setcustomerData] = useState({
    name: "",
    email: "",
    number: "",
    company_address: "",
    point_of_contact: "",
  });

  // validations
  const [nameError, setnameError] = useState(false);
  const [emailError, setemailError] = useState(false);
  const [numberError, setnumberError] = useState(false);
  const [addError, setaddError] = useState(false);
  const [pocError, setpocError] = useState(false);

  const handleInputChange = (field: any, e: string) => {
    setcustomerData((prev) => ({
      ...prev,
      [field]: e,
    }));
  };

  const saveCustomerData = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (customerData.name == "") {
      setnameError(true);
    } else if (customerData.point_of_contact == "") {
      setpocError(true);
    } else if (!emailRegex.test(customerData.email)) {
      setemailError(true);
    } else if (customerData.number.length !== 10) {
      setnumberError(true);
    } else if (customerData.company_address == "") {
      setaddError(true);
    } else {
      onSave(customerData), resetCustomerData();
    }
  };

  const resetCustomerData = () => {
    setcustomerData({
      name: "",
      email: "",
      number: "",
      company_address: "",
      point_of_contact: "",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => {
            onClose(false), resetCustomerData();
          }}
        />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={modalRef}
          className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 w-full max-w-xs sm:max-w-lg sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-headline"
                >
                  Add new customer
                </h3>
                <button
                  onClick={() => {
                    onClose(false), resetCustomerData();
                  }}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                >
                  <X className="w-5 h-5" />
                </button>
                <div className="mt-4">
                  <form className="space-y-4">
                    <div>
                      <label
                        htmlFor="company-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Customer/Company name
                        <label className="text-red-600">*</label>
                      </label>
                      <input
                        type="text"
                        id="company-name"
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Enter company name"
                        value={customerData.name}
                        onChange={(e) => {
                          handleInputChange("name", e.target.value),
                            setnameError(false);
                        }}
                      />
                      {nameError && (
                        <Validation name={"please enter company name"} />
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="contact-person"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company point of contact
                        <label className="text-red-600">*</label>
                      </label>
                      <input
                        type="text"
                        id="contact-person"
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Point contact number"
                        value={customerData.point_of_contact}
                        onChange={(e) => {
                          handleInputChange("point_of_contact", e.target.value),
                            setpocError(false);
                        }}
                      />
                      {pocError && (
                        <Validation name={"please enter point of contact"} />
                      )}
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email<label className="text-red-600">*</label>
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          placeholder="Enter email id"
                          value={customerData.email}
                          onChange={(e) => {
                            handleInputChange("email", e.target.value);
                            setemailError(false);
                          }}
                        />
                        {emailError && (
                          <Validation name={"please enter valid email"} />
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="contact-number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Contact number
                          <label className="text-red-600">*</label>
                        </label>
                        <input
                          type="tel"
                          id="contact-number"
                          className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          placeholder="Enter Contact number"
                          value={customerData.number}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setnumberError(false);
                            if (/^\d*$/.test(inputValue)) {
                              handleInputChange("number", inputValue);
                            }
                          }}
                        />
                        {numberError && (
                          <Validation
                            name={"please enter 10 digit valid number"}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="company-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Company address<label className="text-red-600">*</label>
                      </label>
                      <textarea
                        id="company-address"
                        rows={3}
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Write here"
                        value={customerData.company_address}
                        onChange={(e) => {
                          handleInputChange("company_address", e.target.value);
                          setaddError(false);
                        }}
                      />
                      {addError && <Validation name={"plaese enter address"} />}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              onClick={() => saveCustomerData()}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface props {
  name: string;
}

const Validation = ({ name }: props) => {
  return (
    <label className="text-[11px] ml-1 absolute text-red-500">{name}</label>
  );
};
