import { configureStore } from "@reduxjs/toolkit";
import sideDrawerReducer from "./reducer/side_drawer";
import sideDrawerTabReducer from "./reducer/side_drawer_tab"; // ensure naming consistency

export const store = configureStore({
  reducer: {
    sideDrawer: sideDrawerReducer,
    sideDrawerTab: sideDrawerTabReducer,
  },
});
